import {
  SET_EXPANDED,
  SET_ROLLOVER,
  CLOSE_INFOWINDOWS,
} from "../constants/ActionTypes";

const initialState = {
  rollover: null,
  expanded: null,
};

const infowindows = (state = { ...initialState }, action) => {
  switch (action.type) {
    case SET_EXPANDED:
      return {
        ...state,
        expanded: action.expanded,
      };
    case SET_ROLLOVER:
      return {
        ...state,
        rollover: action.rollover,
      };
    case CLOSE_INFOWINDOWS:
      return { ...initialState };
    default:
      return state;
  }
};

export default infowindows;
