import {
  SHOW_SIDEBAR,
  SHOW_MESSAGE_FORM,
  UPDATE_ZOOM,
  UPDATE_CENTER,
  UPDATE_BOUNDS
} from "../constants/ActionTypes"

import * as Constants from "../utils/constants"

const initialState = {
  showSidebar: true,
  showMessageForm: false,
  zoom: Constants.DEFAULT_MAP_ZOOM,
  center: {
    lat: Constants.DEFAULT_MAP_CENTRE_LAT,
    lng: Constants.DEFAULT_MAP_CENTRE_LONG
  }
}

const app = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SIDEBAR:
      return {
        ...state,
        showSidebar: action.showSidebar
      }
    case SHOW_MESSAGE_FORM:
      return {
        ...state,
        showMessageForm: action.showMessageForm
      }
    case UPDATE_ZOOM:
      return {
        ...state,
        zoom: action.zoom
      }
    case UPDATE_CENTER:
      return {
        ...state,
        center: action.center
      }
    case UPDATE_BOUNDS:
      return {
        ...state,
        bounds: action.bounds
      }
    default:
      return state
  }
}

export default app