import {
  AUTH_REQUEST,
  AUTH_UPDATE,
  AUTH_FAILURE,
  AUTH_DESTROY
} from "../constants/ActionTypes"

const initialState = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : { authenticated: false, fetching: false }

const auth = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null
      }
    case AUTH_UPDATE:
      return {
        ...action.auth,
        fetching: false
      }
    case AUTH_FAILURE:
      return {
        ...action.auth,
        fetching: false,
        error: action.error
      }
    case AUTH_DESTROY:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default auth