import 'whatwg-fetch'

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT

export function API(endpoint, method, data = null) {
  let config = {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }

  if (method === "POST" || method === "PUT") {
    config.body = JSON.stringify(data)
  }

  return new Promise((resolve, reject) => {
    fetch(`${apiEndpoint}${endpoint}`, config)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(resolve)
      .catch(reject)
  })
}

function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    try {
      throw new Error(`HTTP Error ${response.status} ${response.statusText}`)
    }
    catch (e) {
      console.log(e)
    }
    return response
  }
}

function parseJSON(response) {
  return response.json()
}

export default API