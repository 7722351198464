import { SET_PHOTO_MODAL, CLEAR_PHOTO_MODAL } from "../constants/ActionTypes";

const initialState = {
  driver: null,
  job: null,
  stop: null,
  index: null,
};

const photo = (state = { ...initialState }, action) => {
  switch (action.type) {
    case SET_PHOTO_MODAL:
      return action.payload;
    case CLEAR_PHOTO_MODAL:
      return initialState;
    default:
      return state;
  }
};

export default photo;
