import { GET_ETA } from "../constants/ActionTypes";

const etas = (state = {}, action) => {
  switch (action.type) {
    case GET_ETA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default etas;
