import { GET_DIRECTIONS } from "../constants/ActionTypes";

const initialState = {};

const directions = (state = initialState, action) => {
  switch (action.type) {
    case GET_DIRECTIONS:
      return { ...state, [action.driver]: action.stops };
    default:
      return state;
  }
};

export default directions;
