import {
  AUTH_REQUEST,
  AUTH_UPDATE,
  AUTH_FAILURE,
  AUTH_DESTROY,
} from "../constants/ActionTypes";
import { push } from "react-router-redux";
import API from "../utils/api";

export function checkAuth() {
  return (dispatch, getState) => {
    const { authenticated } = getState().auth.authenticated;
    if (authenticated) dispatch(push("/"));
  };
}

export const authRequest = () => {
  return {
    type: AUTH_REQUEST,
  };
};

export const authUpdate = (auth) => {
  return {
    type: AUTH_UPDATE,
    auth,
  };
};

export const authFailure = (error) => {
  return {
    type: AUTH_FAILURE,
    error,
  };
};

export const authDestroy = () => {
  return {
    type: AUTH_DESTROY,
  };
};

export function login(credentials) {
  return (dispatch) => {
    dispatch(authRequest());
    return API("/authenticate", "POST", credentials).then((response) => {
      console.log(response);
      if (response.authenticated) {
        dispatch(authUpdate(response));
        localStorage.setItem("auth", JSON.stringify(response));
        dispatch(push("/"));
      } else {
        let message = credentials.auth_token
          ? "Invalid token"
          : "Incorrect username or password";
        dispatch(authFailure(message));
      }
    });
  };
}

export function logout() {
  localStorage.clear("auth");
  return (dispatch) => {
    dispatch(authDestroy(), push("/"), window.location.reload());
  };
}
