import { GET_HISTORY, CREATE_HISTORY_MARKERS, CLEAR_HISTORY } from "../constants/ActionTypes"

const initialState = {
}

const history = (state = initialState, action) => {
  switch (action.type) {
    case GET_HISTORY:
      return {
        ...state,
        ...action.history
      }
    case CREATE_HISTORY_MARKERS:
      return {
        ...action.markers
      }
    case CLEAR_HISTORY:
      return {}
    default:
      return state
  }
}

export default history