export const defaultFilters = {
  "Driver Number": "",
  "Free Pallet Spaces": [0, 24],
  "Total Pallet Spaces": [1, 24],
  Weight: [1000, 35000],
  Clearance: [1.5, 4.8],
  "Internal Width": [1.2, 2.9],
  "Internal Height": [1.2, 3.4],
  "Internal Length": [1.2, 14.4],
  Gates: "",
  "Power Tail Gate": "",
  "Dangerous Goods Certified": false,
  Inductions: [],
  "Park Brake Alarm": false,
  "Reverse Alarm": "",
};

export const defaultOptions = {
  Gates: ["Hanging", "Lift Out", "Curtains", "None"],
  Inductions: ["COVIDVAX", "ALDI", "GHPL", "STARTRACK", "FLETCHERS", "SECON"],
  "Power Tail Gate": ["Any", "Tuck Away", "Full Size", "None"],
  "Reverse Alarm": ["Beeping", "Squawking", "None"],
};
