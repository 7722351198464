import { CREATE_MARKERS, UPDATE_MARKERS } from "../constants/ActionTypes"

const initialState = {
}

const markers = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_MARKERS:
    return {
        ...state,
        ...action.markers
      }
    case UPDATE_MARKERS:
    return {
        ...state,
        ...action.markers
      }
    default:
      return state
  }
}

export default markers