import { SET_ACTIVE_STOP, CLEAR_ACTIVE_STOP } from "../constants/ActionTypes";

const initialState = {};

const activeStop = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_STOP:
      return action.payload;
    case CLEAR_ACTIVE_STOP:
      return {};
    default:
      return state;
  }
};

export default activeStop;
