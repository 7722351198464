export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_UPDATE = "AUTH_UPDATE";
export const AUTH_FAILURE = "AUTH_FAILURE";
export const AUTH_DESTROY = "AUTH_DESTROY";

export const SHOW_SIDEBAR = "SHOW_SIDEBAR";
export const SHOW_MESSAGE_FORM = "SHOW_MESSAGE_FORM";
export const UPDATE_ZOOM = "UPDATE_ZOOM";
export const UPDATE_CENTER = "UPDATE_CENTER";
export const UPDATE_BOUNDS = "UPDATE_BOUNDS";

export const GET_JOBS = "GET_JOBS";

export const UPDATE_FILTER_PROPERTY = "UPDATE_FILTER_PROPERTY";
export const RESET_FILTERS = "RESET_FILTERS";
export const TOGGLE_EXPANDED = "TOGGLE_EXPANDED";
export const TOGGLE_PINNED = "TOGGLE_PINNED";

export const GET_DRIVERS = "GET_DRIVERS";
export const CREATE_MARKERS = "CREATE_MARKERS";
export const UPDATE_MARKERS = "UPDATE_MARKERS";

export const SET_DRIVER = "SET_DRIVER";
export const RESET_DRIVER = "RESET_DRIVER";

export const SET_HISTORY_RANGE = "SET_HISTORY_RANGE";
export const GET_HISTORY = "GET_HISTORY";
export const CREATE_HISTORY_MARKERS = "CREATE_HISTORY_MARKERS";
export const CLEAR_HISTORY = "CLEAR_HISTORY";

export const GET_DIRECTIONS = "GET_DIRECTIONS";

export const FETCH_VICTRAFFIC = "FETCH_VICTRAFFIC";
export const TOGGLE_VICTRAFFIC = "TOGGLE_VICTRAFFIC";

export const SET_ACTIVE_STOP = "SET_ACTIVE_STOP";
export const CLEAR_ACTIVE_STOP = "CLEAR_ACTIVE_STOP";

export const SET_TIME_WINDOW = "SET_TIME_WINDOW";

export const GET_ETA = "GET_ETA";

export const SET_PHOTO_MODAL = "SET_PHOTO_MODAL";
export const CLEAR_PHOTO_MODAL = "CLEAR_PHOTO_MODAL";

export const SET_EXPANDED = "SET_EXPANDED";
export const SET_ROLLOVER = "SET_ROLLOVER";
export const CLOSE_INFOWINDOWS = "CLOSE_INFOWINDOWS";
