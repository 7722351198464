import { combineReducers } from "redux";
import auth from "./auth";
import app from "./app";
import drivers from "./drivers";
import markers from "./markers";
import filters from "./filters";
import jobs from "./jobs";
import driver from "./driver";
import history from "./history";
import directions from "./directions";
import vicTraffic from "./victraffic";
import activeStop from "./activeStop";
import etas from "./etas";
import photo from "./photo";
import infowindows from "./infowindows";

export default combineReducers({
  auth,
  app,
  drivers,
  etas,
  markers,
  filters,
  jobs,
  driver,
  history,
  directions,
  vicTraffic,
  activeStop,
  photo,
  infowindows,
});
