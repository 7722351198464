import { FETCH_VICTRAFFIC, TOGGLE_VICTRAFFIC } from "../constants/ActionTypes";

const initialState = {
  visible: false,
};

const vicTraffic = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VICTRAFFIC:
      return {
        ...state,
        data: action.data,
      };
    case TOGGLE_VICTRAFFIC:
      return {
        ...state,
        visible: !state.visible,
      };
    default:
      return state;
  }
};

export default vicTraffic;
