export const DEFAULT_TRUCK_LOCATION_LAT = -37.9202182;
export const DEFAULT_TRUCK_LOCATION_LONG = 145.1470536;
export const DEFAULT_MAP_CENTRE_LAT = -37.8274812;
export const DEFAULT_MAP_CENTRE_LONG = 144.9352466;
export const DEFAULT_MAP_ZOOM = 11;

export const truckColors = {
  1: "#e6194b",
  2: "#3cb44b",
  4: "#800000",
  6: "#f032e6",
  8: "#f58231",
  10: "#911eb4",
  12: "#808000",
  14: "#0082c8",
  22: "#aa6e28",
  24: "#008080",
};

export const markerValues = {
  Gates: {
    Hanging: "Hanging",
    "Lift Out": "LiftOut",
    Curtains: "Curtains",
    None: "None",
  },
  "Power Tail Gate": {
    Any: "Any",
    "Tuck Away": "TuckAway",
    "Full Size": "FullSize",
    None: "None",
  },
};

export const jobLabels = {
  0: "A",
  1: "B",
  2: "C",
  3: "D",
  4: "E",
};
