import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import createHistory from "history/createBrowserHistory";
import { enableBatching } from "redux-batched-actions";
import { routerMiddleware } from "react-router-redux";
import { Provider } from "react-redux";
import reducers from "./reducers";
import thunk from "redux-thunk";
import registerServiceWorker from "./registerServiceWorker";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./components/App";

import "font-awesome/css/font-awesome.min.css";
import "./index.css";

Sentry.init({
  dsn: "https://52b9f9f9fc7945979a8ec1782eac61fd@sentry.sbmclient.com/5",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const history = createHistory();

const middleware = [thunk, routerMiddleware(history)];

const store = createStore(
  enableBatching(reducers),
  {},
  composeEnhancers(applyMiddleware(...middleware))
);

ReactDOM.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
