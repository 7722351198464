import React, { Component } from "react";
import { ConnectedRouter as Router } from "react-router-redux";
import { Switch, Route } from "react-router-dom";
import { checkAuth } from "../actions/auth";
import { connect } from "react-redux";
import asyncComponent from "./AsyncComponent";

import "./App.css";
import AuthRoute from "./AuthRoute";
// import Dashboard from "./Dashboard"
// import Login from "./Login"

const Login = asyncComponent(() => import("./Login"));
const Dashboard = asyncComponent(() => import("./Dashboard"));

class App extends Component {
  componentDidMount = () => {
    this.props.dispatch(checkAuth());
  };
  render() {
    return (
      <Router history={this.props.history}>
        <Switch>
          <Route path="/login/:token?" component={Login} />
          <AuthRoute exact path="/" component={Dashboard} />
        </Switch>
      </Router>
    );
  }
}
export default connect()(App);
