import { SET_DRIVER, RESET_DRIVER, SET_HISTORY_RANGE } from "../constants/ActionTypes"

const initialState = {
}

const driver = (state = initialState, action) => {
  switch (action.type) {
    case SET_DRIVER:
      return {
        ...state,
        ...action.driver
      }
    case RESET_DRIVER:
      return {}
    case SET_HISTORY_RANGE:
      return {
        ...state,
        ...action.range
      }
    default:
      return state
  }
}

export default driver