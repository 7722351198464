import {
  UPDATE_FILTER_PROPERTY,
  RESET_FILTERS,
  TOGGLE_EXPANDED,
  TOGGLE_PINNED,
} from "../constants/ActionTypes";
import { defaultFilters } from "../constants/Defaults";

const localStoragePinned = localStorage.getItem("pinned");
const pinned = localStoragePinned ? JSON.parse(localStoragePinned) : {};
if (!Object.keys(pinned).length) {
  Object.keys(defaultFilters).forEach((key) => (pinned[key] = false));
  localStorage.setItem("pinned", JSON.stringify(pinned));
}

const initialState = {
  values: { ...defaultFilters },
  expanded: {
    vehicle: false,
    driver: false,
    primary: true,
  },
  pinned: pinned,
};

const filters = (state = { ...initialState }, action) => {
  switch (action.type) {
    case UPDATE_FILTER_PROPERTY:
      return {
        ...state,
        values: {
          ...state.values,
          [action.prop]: action.newVal,
        },
      };

    case RESET_FILTERS:
      return {
        ...state,
        values: { ...defaultFilters },
      };

    case TOGGLE_EXPANDED:
      return {
        ...state,
        expanded: {
          ...state.expanded,
          [action.category]: !state.expanded[action.category],
        },
      };

    case TOGGLE_PINNED:
      const newState = {
        ...state,
        pinned: {
          ...state.pinned,
          [action.name]: !state.pinned[action.name],
        },
      };
      localStorage.setItem("pinned", JSON.stringify(newState.pinned));
      return newState;

    default:
      return state;
  }
};

export default filters;
