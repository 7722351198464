import React from "react"
import { Route, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { withRouter } from "react-router"

const AuthRoute = ({ component: Component, authenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return authenticated ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { redirect: props.location }
              }}
            />
          )
      }}
    />
  )
}

const mapState = state => {
  return {
    authenticated: state.auth.authenticated
  }
}

export default withRouter(connect(mapState)(AuthRoute))
